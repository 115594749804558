<template lang="">
    <div class="row">
      <div class="col-12">
        
        <div class="card dveb-white-card">
          <b-tabs content-class="mt-3 " class="nav nav-tabs nav-tabs-line">
            <b-tab title="Teknik Destek Dosyaları">
              <SupportDetailD />
            </b-tab>
            <b-tab title="Teknik Destek Talepleri">
              <SupportDetailT/>
            </b-tab>
            <b-tab title="Teknik Destek Talep Dosyaları">
               <SupportDetailTekD/>
            </b-tab>            
          </b-tabs>
        
        </div>
      </div>
    </div>
</template>
<script>
export default {
    components:{
        SupportDetailD: () =>import('@/components/support/SupportDetail/TeknikDestekD.vue'),
        SupportDetailT: () => import('@/components/support/SupportDetail/TeknikDestekT.vue'),
        SupportDetailTekD: () => import('@/components/support/SupportDetail/TeknikDestekTalepD.vue'),
    }
}
</script>
<style lang="">
    
</style>